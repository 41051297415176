import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const Aligner = makeShortcode("Aligner");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "plus-de-public",
      "style": {
        "position": "relative"
      }
    }}>{`Plus de public`}<a parentName="h1" {...{
        "href": "#plus-de-public",
        "aria-label": "plus de public permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Nous avons commencé cette page avec beaucoup plus de contenu, mais nous avons été arrêtés par une simple idée : la culture se passe, elle n'est pas archivée. C'est un mot qui se décline uniquement au présent.`}</p>
    <p>{`Notre position est maintenant que vous verrez comment `}<a parentName="p" {...{
        "href": "/build/culture"
      }}>{`nous abordons la culture en étant avec nous`}</a>{`, plutôt que de lire notre section Ressources. Nous ne vous laissons que les moindres indications à considérer.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Rhea Myers`}</strong></p>
          <p>{`L'une des premières personnes au monde à commencer à penser à l'art et à la crypto, il y a bien longtemps en 2012. Ce blog regorge de joyaux cachés.`}</p>
          <Button to="https://rhea.art/" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Aimer
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Future of Fandom`}</strong></p>
          <p>{`Un deck incroyable de Zoe. salutation à Adrian le Bas pour le partage.`}</p>
          <Button to="https://docs.google.com/presentation/d/1E_0-hpaaXdqiGNr06SkpnOglkGPiACSDLK6Tu8CS4vg/edit?usp=sharing" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Féliciter
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={'40px'} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Ouvrir le Metaverse`}</strong></p>
          <p>{`6529 tweetant des vérités profondes.`}</p>
          <Button to="https://twitter.com/punk6529/status/1445468399656595456" sx={{
            alignSelf: 'flex-start',
            mt: 'auto'
          }} mdxType="Button">
  Sing It
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <Aligner center mdxType="Aligner">
      <pre><code parentName="pre" {...{}}>{`Trois conseils pour vous aider
trianguler la lune.

A quoi bon sont-ils
quand ça grandit en toi,
chacun de nous une boîte de pétri
pour le jeu de Dieu,
ce jeu sans fin
de donner et recevoir,
haut et bas,
gloire et la fortune.

Avance, mon ami,
il n'y a rien à trouver.

Nous sommes déjà poussière,
quelques morceaux de plus
dans le rêve numérique
que nous pouvons être n'importe où ailleurs
mais dans la paume de l'amour,
alors prends courage
embrasser ta blessure
tout en haut.

Il peut juste fleurir.
Nous pouvons juste donner naissance à un nouveau monde.
`}</code></pre>
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      